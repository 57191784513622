import type { BaseData, PaginationData } from 'egenie-utils';
import { MainSubStructureModel, Programme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import api from '../../utils/api';
import styles from './index.less';
import React from 'react';
import ClothingAgentVendorRemarkStore from './modal/remark/model';
import ClothingAgentVendorInviteStore from './modal/invite/model';

export default class ClothingAgentVendorStore {
  public editRemarkStore = new ClothingAgentVendorRemarkStore({ parent: this });

  public inviteStore = new ClothingAgentVendorInviteStore();

  constructor() {
    this.getClothingAgentList();
  }

  @action
  public getClothingAgentList = async(search = '') => {
    const req = await request<PaginationData<{ id: string; name: string; }>>({
      url: api.getVendorDictPage,
      method: 'POST',
      data: {
        page: 1,
        pageSize: 1000,
        search,
      },
    });
    const arr = req.data.list?.map((item) => ({
      label: item.name,
      value: `${item.id}`,
    })) || [];
    this.programme.filterItems.addDict({ vendorTenantIds: arr });
  };

  // 邀请供应商
  private acceptInvitation = () => {
    this.inviteStore.onShow();
  };

  private handleEdit = (clothingAgentVendorRelationId: string, remark: string) => {
    this.editRemarkStore.onShow(clothingAgentVendorRelationId, remark);
  };

  // 主表内容
  @observable
  public mainSubStructureModel = new MainSubStructureModel({
    pageId: '431',
    buttons: [
      {
        permissionId: '183',
        text: '邀请供应商',
        handleClick: () => {
          this.acceptInvitation();
        },
      },
    ],
    grid: {
      primaryKeyField: 'clothingAgentVendorRelationId',
      columns: [
        {
          key: 'vendorName',
          name: '供应商名称',
          width: 200,
        },
        {
          key: 'cooperateStatusName',
          name: '合作状态',
          width: 200,
        },
        {
          key: 'contact',
          name: '联系人',
          width: 200,
        },
        {
          key: 'mobile',
          name: '手机号',
          width: 200,
        },
        {
          key: 'remark',
          name: '备注',
          width: 150,
          formatter: ({ row }) => {
            const {
              remark,
              clothingAgentVendorRelationId,
            } = row;
            return (
              <div className={styles.remark}>
                <span className={styles.text}>
                  {remark}
                </span>
                <i
                  className={`icon-edit ${styles.editIcon}`}
                  onClick={() => this.handleEdit(clothingAgentVendorRelationId, remark)}
                />
              </div>
            );
          },
        },
        {
          key: 'cooperateTime',
          name: '合作时间',
          width: 150,
        },
        {
          key: 'unSettleCommission',
          name: '待结算佣金',
          width: 150,
        },
        {
          key: 'settleCommission',
          name: '已结算佣金',
          width: 150,
        },
        {
          key: 'totalAmount',
          name: '销售额',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      forceRowClick: false,
      showCheckBox: true,
      sortByLocal: false,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: 'ClothingAgentVendor',
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        console.log('params...', params);
        const postParams = { ...filterParams };
        if (filterParams.dateValue) {
          const startDate = filterParams.dateValue.split(',')[0];
          const endDate = filterParams.dateValue.split(',')[1];
          postParams.startDate = startDate;
          postParams.endDate = endDate;
          delete postParams.dateValue;
          delete postParams.dateType;
        }

        return request<BaseData<any>>({
          url: api.getClothingAgentVendorRelation,
          method: 'post',
          data: {
            ...postParams,
            ...rest,
          },
        });
      },
    },

  });

  @observable
  public programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: [
      {
        type: 'date',
        field: 'time',
        label: '日期类型',
        selectValue: 'createTime',
        data: [
          {
            value: 'createTime',
            label: '合作时间',
          },
        ],
      },
      {
        type: 'select',
        field: 'vendorTenantIds',
        label: '供应商名称',
        mode: 'multiple',
        isParamList: true,
        onSearchCallback: this.getClothingAgentList,
      },
      {
        type: 'select',
        label: '合作状态',
        field: 'cooperateStatus',
        mode: 'multiple',
        isParamList: true,
        data: [
          {
            label: '合作中',
            value: '1',
          },
          {
            label: '已终止',
            value: '2',
          },
        ],
      },
      {
        type: 'input',
        label: '备注',
        field: 'remark',
      },
    ],
    moduleName: 'ClothingAgentVendor',
    showProgrammeCount: true,
  });
}
