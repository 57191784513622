import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';

export default class ClothingAgentVendorInviteStore {
  constructor() {
    this.getUserInfo();
  }

  public userInfo = {
    name: '',
    tenantId: '',
  };

  public getUserInfo = () => {
    request<any>({ url: '/api/dashboard/user' }).then((res) => {
      this.userInfo = res;
    });
  };

  @observable public visible = false;

  @action
  public onShow = () => {
    this.visible = true;
  };

  @action
  public onCancel = () => {
    this.visible = false;
  };
}
