import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type ClothingAgentVendorStore from '../../model';
import api from '../../../../utils/api';

export default class ClothingAgentVendorRemarkStore {
  @observable public visible = false;

  @observable public clothingAgentVendorRelationId = null;

  @observable public remark = '';

  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent: ClothingAgentVendorStore;

  @action
  public onShow = (clothingAgentVendorRelationId: string, remark: string) => {
    this.clothingAgentVendorRelationId = clothingAgentVendorRelationId;
    this.remark = remark;
    this.visible = true;
    console.log('onshow');
  };

  @action
  public onCancel = () => {
    this.visible = false;
    this.clothingAgentVendorRelationId = null;
    this.remark = '';
  };

  @action
  public onSave = async() => {
    await request({
      url: api.updateRemark,
      method: 'POST',
      data: {
        id: this.clothingAgentVendorRelationId,
        remark: this.remark,
      },
    });
    this.visible = false;
    this.parent?.programme.handleSearch();
  };

  @action
  public textAreaChanged = (text: string) => {
    this.remark = text;
  };
}
