import { ProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Model from './model';
import styles from './index.less';
import ClothingAgentVendorEditRemark from './modal/remark';
import ClothingAgentVendorInvite from './modal/invite';

const ClothingAgentVendor = () => {
  const store = useMemo(() => new Model(), []);
  const {
    programme,
    editRemarkStore,
    inviteStore,
  } = store;
  return (
    <div className={styles.page}>
      <ProgrammeComponent store={programme}/>
      <ClothingAgentVendorEditRemark store={editRemarkStore}/>
      <ClothingAgentVendorInvite store={inviteStore}/>
    </div>
  );
};
export default observer(ClothingAgentVendor);
