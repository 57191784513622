import React from 'react';
import { Input, Modal } from 'antd';
import type Model from './model';
import { observer } from 'mobx-react';

const ClothingAgentVendorEditRemark = ({ store }: { store: Model; }) => {
  const {
    visible,
    onCancel,
    onSave,
    textAreaChanged,
    remark,
  } = store;
  return (
    <Modal
      centered
      maskClosable={false}
      onCancel={onCancel}
      onOk={onSave}
      open={visible}
      title="备注"
      width={500}
    >
      <Input.TextArea
        defaultValue={remark}
        maxLength={200}
        onChange={(e) => textAreaChanged(e.target.value)}
        placeholder="请输入备注信息"
        showCount
        style={{ height: 200 }}
      />
    </Modal>

  );
};
export default observer(ClothingAgentVendorEditRemark);
