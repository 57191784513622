import React from 'react';
import { Modal, Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import type Model from './model';
import { observer } from 'mobx-react';

const ClothingAgentVendorInvite = ({ store }: { store: Model; }) => {
  const {
    visible,
    onCancel,
    userInfo,
  } = store;

  const link = React.useMemo(() => {
    const { tenantId, name } = userInfo;
    return `${window.location.origin}/egenie-webapp-operation/clothingAgent/inviteProvider?id=${tenantId}&name=${name}`;
  }, [userInfo]);

  return (
    <Modal
      centered
      footer={(
        <Button onClick={onCancel}>
          取消
        </Button>
      )}
      maskClosable={false}
      onCancel={onCancel}
      open={visible}
      title="邀请供应商"
      width={800}
    >
      <div>
        邀请链接：
       
        <a>
          {link}
        </a>
        <div>
          <CopyToClipboard
            onCopy={() => {
              message.success('复制成功');
            }}
            style={{ textAlign: 'right' }}
            text={link}
          >
            <a>
              复制邀请链接
            </a>
          </CopyToClipboard>
        </div>
      </div>
      <div/>
    </Modal>

  );
};
export default observer(ClothingAgentVendorInvite);
